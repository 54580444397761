/**
 * Adds or updates a URL parameter in the current URL without reloading the page.
 * Utilizes the window.history.pushState method to achieve this.
 *
 * @param {string} key - The URL parameter key to add or update.
 * @param {string} value - The value to set for the given URL parameter key.
 */

function addOrUpdateUrlParam(key, value) {
    const urlObj = new URL(window.location.href);

    // Create the new query string with the key-value pair
    const queryString = urlObj.search ? urlObj.search + `&${key}=${value}` : `?${key}=${value}`;

    // Construct the new URL
    const newUrl = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}${queryString}${urlObj.hash}`;

    window.history.pushState({}, '', newUrl);
}

/**
 * Periodically checks if a specific word is present in the current URL.
 * Once the word is found, the specified callback function is executed
 * and the current URL is passed as an argument to the callback.
 *
 * @param {string} word - The word to look for in the URL.
 * @param {Function} callback - The function to execute when the word is found.
 * @param {number} interval - The time interval (in ms) for checking the URL. Default is 1000 ms.
 */

function waitForUrlWord(word, callback, interval = 1000) {
    // Create an interval to periodically check the URL
    const checkInterval = setInterval(() => {
        const currentUrl = window.location.href;

        // Check if the word exists in the URL
        if (currentUrl.includes(word)) {
            // Clear the interval to stop checking
            clearInterval(checkInterval);

            // Run the callback function and pass the current URL
            callback(currentUrl);
        }
    }, interval);
}


/**
 * Periodically checks if a specific URL parameter is present in the current URL.
 * Once the parameter is found, the specified callback function is executed with the parameter value.
 *
 * @param {string} param - The URL parameter to look for.
 * @param {Function} callback - The function to execute when the parameter is found.
 * @param {number} interval - The time interval (in ms) for checking the URL. Default is 1000 ms.
 */
function waitForUrlParam(param, callback, interval = 1000) {
    // Create an interval to periodically check for the URL parameter
    const checkInterval = setInterval(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        // Check if the parameter exists in the URL
        if (params.has(param)) {
            // Clear the interval to stop checking
            clearInterval(checkInterval);

            // Run the callback function with the parameter value
            callback(params.get(param));
        }
    }, interval);
}

/**
 * Sets a cookie with a specified name, value, and number of days until it expires.
 * If the number of days is not provided, the cookie will be set as a session cookie.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} [days] - The number of days until the cookie should expire.
 */
function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    console.log("Cookie set: " + name + "=" + value);
}

/**
 * Deletes a cookie with a specified name by setting its expiration date to a past date.
 * The path is set to the root to ensure the cookie is deleted for the entire domain.
 *
 * @param {string} name - The name of the cookie to delete.
 */
function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}

/**
 * Checks if a cookie with the specified name is set.
 * Returns true if the cookie exists, false otherwise.
 *
 * @param {string} cookieName - The name of the cookie to check for.
 * @returns {boolean} - True if the cookie exists, false if it does not.
 */
function isCookieSet(cookieName) {
    return document.cookie.split(';').some(cookie => cookie.trim().startsWith(cookieName + '='));
}

/**
 * Waits asynchronously for all cookies specified in the cookieNames array to be set.
 * Once all cookies are present, the provided callback function is executed.
 * Uses recursion and setInterval to perform the check at a set interval.
 *
 * @param {string[]} cookieNames - An array of strings representing the cookie names to wait for.
 * @param {Function} callback - The callback function to execute once all cookies are set.
 * @param {number} [interval=1000] - Optional. The time interval (in ms) for checking the cookies. Defaults to 1000 ms.
 */
function waitForCookiesSet(cookieNames, callback, interval = 1000) {
    const checkCookies = () => {
        if (cookieNames.every(isCookieSet)) {
            clearInterval(checkInterval);
            callback();
        }
    };

    let checkInterval = setInterval(checkCookies, interval);
}

/**
 * Retrieves the value of a cookie with a given name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie if found, otherwise null.
 */
function getCookie(name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
}


const AFFILIATE_ACCOUNT_TYPE = "affiliateAccountType";
const AFFILIATE_FSID = "affiliateFsid";

waitForUrlWord("kontoeroeffnung", (url) => {
    if (url.includes("gemeinschaftskunde"))
        setCookie(AFFILIATE_ACCOUNT_TYPE, "gemeinschaftskunde", 1);
    else if (url.includes("einzelkunde"))
        setCookie(AFFILIATE_ACCOUNT_TYPE, "gemeinschaftskunde", 1);
})

waitForUrlParam("fsid", (param) => {
    setCookie(AFFILIATE_FSID, param, 1);
});

waitForCookiesSet([AFFILIATE_ACCOUNT_TYPE, AFFILIATE_FSID], () => {
    const partnerId = getCookie(AFFILIATE_ACCOUNT_TYPE) + ";" + getCookie(AFFILIATE_FSID);
    addOrUpdateUrlParam("partnerId", partnerId);
    console.log("partnerId=" + partnerId);
    deleteCookie(AFFILIATE_ACCOUNT_TYPE);
    deleteCookie(AFFILIATE_FSID);
})